import React from "react"
import Training from "./training"

const Trainings = () => {
    const trainings = [
        {
            cover: 'content/DomainDrivers.jpg',
            title: 'Domain Drivers',
            description: 'DDD (Domain-Driven Design) od A do Z. Wreszcie dokładnie, świadomie wszystko zrozumiesz!',
            homepage: 'https://domaindrivers.pl/',
            price: '2 499 zł',
            attendees: '2560 uczestników',
            mentorsLabel: 'Mentorzy', 
            mentors: [
                {
                    name: 'Sławomir Sobótka',
                    picture: 'content/LF_Slawek.jpg'
                },
                {
                    name: 'Jakub Pilimon',
                    picture: 'content/LF_Pilimon.jpg'
                },
            ],
            tags: ['DDD', 'modelowanie']
        },
        {
            cover: 'content/DNA.jpg',
            title: 'Droga Nowoczesnego Architekta',
            description: 'KULTOWE szkolenie DNA: Droga Nowoczesnego Architekta. 20 tygodni zaawansowanej nauki!',
            agenda: 'https://droganowoczesnegoarchitekta.pl/agenda',
            buy: 'https://droganowoczesnegoarchitekta.pl/kup',
            homepage: 'https://droganowoczesnegoarchitekta.pl',
            price: '2 849 zł',
            attendees: '9087 uczestników',
            mentorsLabel: 'Mentorzy', 
            mentors: [
                {
                    name: 'Jakub Kubryński',
                    picture: 'content/DNAkuba-kubrynski.JPG'
                },
                {
                    name: 'Łukasz Szydło',
                    picture: 'content/dna_lukasz-szydlo.jpg'
                },
                {
                    name: 'Jakub Pilimon',
                    picture: 'content/DNA_kuba-pilimon.jpg'
                },
            ],
            tags: ['architektura', 'zaawansowane']
        },
        {
            cover: 'content/LegacyFighter.png',
            title: 'Legacy Fighter',
            description: 'Legacy, refactoring, modelowanie... na WIELU poziomach. Absolutny MUST-HAVE dla wszystkich pracujących z kodem!',
            agenda: 'https://legacyfighter.pl/agenda',
            buy: 'https://legacyfighter.pl/kup',
            homepage: 'https://legacyfighter.pl',
            price: '1 899 zł',
            attendees: '4324 uczestników',
            mentorsLabel: 'Mentorzy', 
            mentors: [
                {
                    name: 'Mariusz Gil',
                    picture: 'content/LF_Mariusz.jpg'
                },
                {
                    name: 'Jakub Pilimon',
                    picture: 'content/LF_Pilimon.jpg'
                },
                {
                    name: 'Sławomir Sobótka',
                    picture: 'content/LF_Slawek.jpg'
                },
            ],
            tags: ['legacy', 'refactoring', 'junior-senior']
        },
        {
            cover: 'content/ANF.jpg',
            title: 'Architektura na Froncie',
            description: 'PRO wiedza z Frontendu na poziomie Señor-deva z Tomkiem Ducinem',
            agenda: 'http://architekturanafroncie.pl/agenda',
            buy: 'http://architekturanafroncie.pl/kup',
            homepage: 'https://architekturanafroncie.pl',
            price: '1 899 zł',
            attendees: '3652 uczestników',
            mentorsLabel: 'Mentor', 
            mentors: [
                {
                    name: 'Tomasz Ducin',
                    picture: 'content/ANF_Ducin.jpg'
                }
            ],
            tags: ['frontend', 'architektura', 'zaawansowane']
        },
        {
            cover: 'content/SmartTesting.jpg',
            title: 'SmartTesting',
            description: 'Najlepsze miejsce do nauki Testów dla programistów (jednostkowych, integracyjnych i...)',
            agenda: 'https://smarttesting.pl/agenda',
            buy: 'https://smarttesting.pl/kup',
            homepage: 'https://smarttesting.pl',
            price: '1 799 zł',
            attendees: '2340 uczestników',
            mentorsLabel: 'Mentorzy', 
            mentors: [
                {
                    name: 'Olga Maciaszek-Sharma',
                    picture: 'content/SmartTestig_Olga.png'
                },
                {
                    name: 'Marcin Grzejszczak',
                    picture: 'content/SmartTesting_Marcin.png'
                },
            ],
            tags: ['testy', 'mid-dev', 'senior-dev']
        },
        {
            cover: 'content/DBM.jpg',
            title: 'DBMaster',
            description: 'Szkolenie z relacyjnych baz danych (SQL Server, Oracle, PostgreSQL, MySQL) by Damian Widera.',
            agenda: 'https://dbmaster.pl/agenda',
            buy: 'https://dbmaster.pl/kup',
            homepage: 'https://dbmaster.pl',
            price: '1 699 zł',
            attendees: '2406 uczestników',
            mentorsLabel: 'Mentor', 
            mentors: [
                {
                    name: 'Damian Widera',
                    picture: 'content/IMG-Widera.png'
                },              

            ],
            tags: ['bazy danych', 'backend']
        },
        {
            cover: 'content/WTF.jpg',
            title: 'WTF: Co Ten Frontend',
            description: 'Skuteczny Program Nauki Podstaw Frontendu by Maciek Korsan.',
            agenda: 'https://cotenfrontend.pl/agenda/',
            buy: 'https://cotenfrontend.pl/kup',
            homepage: 'https://cotenfrontend.pl',
            price: '1 499 zł',
            attendees: '1936 uczestników',
            mentorsLabel: 'Mentor',
            mentors: [
                {
                    name: 'Maciek Korsan',
                    picture: 'content/korsan.png'
                },
            ],
            tags: ['frontend', 'junior', 'html', 'css', 'javascript']
        },
        {
            cover: 'content/KursGita.jpg',
            title: 'Kurs Gita',
            description: 'WSZYSTKO o Gicie by Maciej Aniserowicz.',
            agenda: 'https://kursgita.pl/agenda.html',
            buy: 'https://kursgita.pl/kup',
            homepage: 'https://kursgita.pl',
            price: '299 zł',
            attendees: '5245 uczestników',
            mentorsLabel: 'Mentor', 
            mentors: [
                {
                    name: 'Maciej Aniserowicz',
                    picture: 'content/Kurs_Gita_Speakers_Maciek.png'
                },
            ],
            tags: ['git', 'programista', 'tester']
        },
        {
            cover: 'content/devbites.jpg',
            title: 'devbites: krótkie kursy programistyczne',
            description: 'Nasza nowa platforma z krótkimi, esencjonalnymi kursami programistycznymi!',
            buy: 'https://devbites.pl/',
            homepage: 'https://devbites.pl/',
            price: '159-499 zł',
            attendees: '',
            mentorsLabel: 'Mentors', 
            mentors: [
                {
                    name: 'Jakub Pilimon',
                    picture: 'content/LF_Pilimon.jpg'
                },
                {
                    name: 'Mariusz Gil',
                    picture: 'content/LF_Mariusz.jpg'
                },
                {
                    name: '(i inni...)',
                    picture: 'content/devbites_inni.jpg'
                },
            ],
            tags: ['oop', 'testing', 'backend', 'frontend', 'junior-mid']
        },
        {
            cover: 'content/DNA-EN.jpg',
            title: 'Designing Next-Gen Architecture',
            description: 'A best-selling, comprehensive career growth program for mid- and senior-developers!',
            buy: 'https://nextgenarchitecture.com/buy',
            homepage: 'https://nextgenarchitecture.com/',
            price: '999 EUR net',
            attendees: '',
            mentorsLabel: 'Mentors', 
            mentors: [
                {
                    name: 'Jakub Kubryński',
                    picture: 'content/DNAkuba-kubrynski.JPG'
                },
                {
                    name: 'Łukasz Szydło',
                    picture: 'content/dna_lukasz-szydlo.jpg'
                },
                {
                    name: 'Jakub Pilimon',
                    picture: 'content/DNA_kuba-pilimon.jpg'
                },
            ],
            tags: ['architecture', 'advanced', 'english']
        },
    ]

    return <section id="szkolenia" className="ds-container">
        <h2 className="ds-heading">Dostępne szkolenia</h2>
        <p className="ds-heading-description">
        Sprawdź ofertę naszych szkoleń i agendę każdego z nich. Uniwersalne, kompleksowe... niezbędne w programistycznej karierze!<br />
        Aktualnie nie prowadzimy sprzedaży, ale zachęcamy do zapisu na darmowy, merytoryczny mailing w temacie szkolenia!
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
           {trainings.map((item, index) => <Training key={index} {...item}/>)}
        </div>
    </section>
}

export default Trainings;